<template>
  <div class="flex">
    <div class="flex-1 min-width">
      <h1 class="logo">
        <img
          :src="`${api}/face_img/login_menu_left?d=${host}`"
          height="30"
          type="image/svg+xml"
        />
      </h1>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo header-nav"
        mode="horizontal"
        @select="handleSelect"
        v-for="(menu, index) in menus"
        :key="index"
      >
        <el-submenu index="1" v-if="menu.hasOwnProperty('children')">
          <template slot="title">{{ menu.name }}</template>
          <el-menu-item :index="menuIndex(menu.id)">{{
            menu.name
          }}</el-menu-item>
          <el-menu-item
            :index="menuIndex(children.id)"
            v-for="(children, sub_index) in menu.children"
            :key="sub_index"
            >{{ children.name }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item :index="menuIndex(menu.id)" v-else>{{
          menu.name
        }}</el-menu-item>
      </el-menu>
    </div>
    <!--  导航右侧菜单栏  -->
    <el-menu
      :default-active="activeIndex2"
      class="el-menu-demo information"
      mode="horizontal"
    >
      <el-submenu index="1">
        <template slot="title">
          <el-button
            round
            class="jszc"
            v-if="version === 'shwj'"
            @click="openDataV"
            >可视化大屏</el-button
          >
          <img
            src="@/assets/images/common/touxiang_shwj.svg"
            alt=""
            width="32"
            height="32"
            class="avatar"
            v-if="version === 'shwj'"
          />
          <img
            src="@/assets/images/common/touxiang.svg"
            alt=""
            width="32"
            height="32"
            class="avatar"
            v-else
          />
          <span class="name">{{ user.name }}</span>
        </template>
        <el-menu-item>
          <div class="infor_cont">
            <div class="flex infor_top">
              <img
                src="@/assets/images/common/touxiang_shwj.svg"
                alt=""
                width="32"
                height="32"
                class="avatar2"
                v-if="version === 'shwj'"
              />
              <img
                src="@/assets/images/common/touxiang.svg"
                alt=""
                width="32"
                height="32"
                class="avatar2"
                v-else
              />
              <div>
                <p>{{ user.customer_name }}</p>
                <p>使用期限：{{ bundle.expired }}</p>
              </div>
            </div>
            <p class="time">{{ expired() }}</p>
            <dl>
              <dd @click="handleSelect('account')">
                <span class="iconfont icon-zhanghao"></span>
                <span>帐号管理</span>
              </dd>
              <dd @click="handleSelect('contact')">
                <span class="iconfont icon-tongxunlu"></span>
                <span>通讯录管理</span>
              </dd>
              <dd class="yjgl">
                <el-badge :is-dot="hasCautionMsg" class="item">
                  <span class="iconfont icon-yujing"></span>
                  <span @click="handleSelect('caution/detail')">历史预警</span>
                </el-badge>
                <!-- <el-button round size="mini" class="f-r" @click="cautionShow()"
                  >关闭预警</el-button
                > -->
              </dd>
              <dd @click="handleSelect('material')">
                <span class="iconfont icon-shoucang"></span>
                <span>收藏管理</span>
              </dd>
              <dd class="" @click="handleSelect('shield')">
                <span class="iconfont icon-pingbi"></span>
                <span>屏蔽管理</span>
              </dd>
              <dd @click="handleSelect('orientation')">
                <span class="iconfont icon-jiancexinyuan"></span>
                <span>定向信源</span>
              </dd>
              <dd @click="showNotify()">
                <el-badge class="item">
                  <span class="iconfont icon-xitongtongzhi"> </span
                  ><span>系统通知</span>
                </el-badge>
              </dd>
            </dl>
            <p class="t-c exit">
              <el-button
                type="text"
                icon="iconfont icon-tuichu"
                @click="signOut"
                >退出登录</el-button
              >
            </p>
          </div>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script type="application/javascript">
import { mapGetters } from 'vuex';
import { localDbClear } from '@/utils/helpers';
import { logout } from '@/api/user';
const _ = window._;
export default {
  name: 'header-nav',
  components: {},
  props: {
    hasCautionMsg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: 'yq',
      activeIndex2: '1',
      version: process.env.VUE_APP_VERSION,
      api: process.env.VUE_APP_API,
      host: process.env.VUE_APP_DOMAIN
    };
  },
  computed: {
    ...mapGetters(['user', 'bundle', 'menus', 'menus_dict'])
  },
  mounted() {
    this.activeIndex = this.$route.name;
  },
  watch: {
    '$route.name': function(name) {
      let index = '';
      _(this.menus_dict).forEach((v, k) => {
        if (k === name) index = k;
      });

      if (index.length >= 1) {
        this.activeIndex = index;
      }
    }
  },
  methods: {
    openDataV() {
      window.open('http://datav.shwj.yuqing.co/', '_blank');
    },
    handleSelect(key, keyPath) {
      if (!key) return false;
      this.activeIndex = key;
      this.$router.push({ path: `/${key}` });
    },
    signOut() {
      logout()
        .then(res => {
          if (res.data.state) {
            localDbClear();
            window.location.href = '/login';
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(err => {
          this.$message.error('注销失败，服务错误');
        });
    },
    cautionShow() {
      this.$emit('showCaution');
    },
    expired() {
      const expired = parseInt(
        (Date.parse(this.bundle.expired) -
          Date.parse(window.$moment().format('YYYY-MM-DD'))) /
          (1 * 24 * 60 * 60 * 1000)
      );
      if (expired < 0) {
        return '您的账号已过期';
      } else {
        return `您的账号将于${expired}天后过期`;
      }
    },
    menuIndex(id) {
      var index = '';
      _(this.menus_dict).forEach((v, k) => {
        if (v === id) index = k;
      });
      return index;
    },
    showNotify() {
      this.$emit('showSystemNotify');
    }
  }
};
</script>
<style scoped>
.min-width {
  min-width: 1440px;
}
h1.logo {
  float: left;
  margin: 15px 35px;
  height: 30px;
  cursor: pointer;
}
h1.logo img {
  float: left;
  height: 30px;
}
.header-nav {
  float: left;
}
.header-nav >>> .el-menu-item,
.header-nav >>> .el-submenu__title {
  font-size: 16px;
}
.information {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 30px;
}
.jszc {
  position: absolute;
  right: 180px;
  background: #fff;
  top: 14px;
  padding: 0 15px;
  height: 30px;
  line-height: 28px;
}
.information >>> .jszc span {
  float: left;
}
.information.el-menu--horizontal >>> .el-submenu .el-submenu__title {
  color: #7c7c7c;
}
.avatar {
  margin-right: 10px;
}
.avatar2 {
  margin-right: 7px;
}
.el-menu--horizontal .el-menu .el-menu-item {
  height: auto;
  color: #606266;
  padding: 0;
}
.infor_cont {
  padding: 10px 10px 0;
  width: 200px;
}
.infor_top {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}
.infor_cont .time {
  font-size: 14px;
  text-align: center;
  line-height: 30px;
}
.infor_cont .time,
.infor_cont:hover .time {
  color: red;
}
.infor_cont dl {
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
  line-height: 30px;
  padding: 10px 5px;
  text-align: left;
  margin-top: 5px;
}
.infor_cont .iconfont {
  margin-right: 10px;
}
.infor_cont dd span:nth-child(2) {
  font-size: 12px;
}
.infor_cont >>> .el-badge__content.is-fixed.is-dot {
  top: 5px;
}
.infor_cont:hover {
  background: #fff;
  color: #606266;
}
.yjgl >>> .el-button--mini {
  padding: 5px;
  margin-top: 3px;
}
/* 退出 */
.exit .el-button--text,
.infor_cont:hover .el-button--text {
  color: #7c7c7c;
}
.exit >>> .iconfont {
  margin-right: 5px;
}
</style>
