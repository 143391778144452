<template>
  <el-container class="h-100" id="side">
    <el-header>
      <header-nav
        @showCaution="showCaution()"
        @showSystemNotify="showSystemNotify"
        :hasCautionMsg="hasCautionMsg"
      />
    </el-header>
    <el-main class="el-main h-100">
      <router-view v-if="routeAlive" ref="page" @reload="reload" />
    </el-main>
    <cautionDialog @reloadCaution="reloadCaution" ref="caution" />
    <div class="yjtx_dialog">
      <h3>
        <span>预警提醒：方案预警</span
        ><i class="el-icon-error" @click="hideWarning"></i>
      </h3>
      <div class="cont">
        <vue-scroll>
          <ul class="list">
            <li
              :class="warningClassName(item.warningType)"
              v-for="(item, index) in warningDialogLists"
              :key="index"
            >
              <h4>
                <a>{{ item.planName }}</a>
                <var>
                  <span>{{ item.warningType.substr(0, 2) }}</span>
                  <span>{{ item.pushTime.split(' ')[1] }}</span>
                  <span class="ico"></span>
                </var>
              </h4>
              <p v-if="item.warningType != '实时预警'">
                <span class="f-l">{{ item.total }}条相关数据</span>
                <a @click="toWarningDetail(item.messageId)" class="f-r cur-p"
                  >查看 &gt;&gt;</a
                >
              </p>
              <dl v-if="item.warningType != '实时预警'">
                <dd v-for="(value, key) in item.mediaCount" :key="key">
                  {{ key }}：{{ value }}
                </dd>
              </dl>
              <p class="bt" v-if="item.warningType == '实时预警'">
                <span v-if="item.grade == '重要'">重要</span>
                <a :href="item.url" target="_blank" v-html="item.title"></a>
              </p>
              <p v-if="item.warningType === '实时预警'">
                <span class="f-l">{{ item.mediaType }}</span>
                <a
                  class="f-l w-104"
                  v-if="item.mediaType == '微博' || item.mediaType == '微信'"
                  >{{ item.author }}</a
                >
                <a class="f-l w-104" v-else>{{ item.site }}</a>
                <a class="f-r">{{ item.published.substr(5) }}</a>
              </p>
            </li>
          </ul>
        </vue-scroll>
      </div>
    </div>
    <el-backtop icon="">
      <div class="toTop"><i class="iconfont icon-fanding"></i></div>
    </el-backtop>
    <system-notify ref="notify" />
  </el-container>
</template>
<script type="application/javascript">
import headerNav from '@components/container/header';
import cautionDialog from '@components/common/caution-dialog.vue';
import systemNotify from '@components/common/system-notify.vue';
import { warningMsg } from '@/api/caution';
import { mapGetters } from 'vuex';
const _ = window._;
export default {
  name: 'index',
  components: {
    headerNav,
    cautionDialog,
    systemNotify
  },
  data() {
    return {
      warningDialogLists: [], //[{warningType: '循环', pushTime: '2019-11-20 22:22:22', published: '2019-11-20', total: 1, planName: '圆通'}]
      routeAlive: true,
      hasCautionMsg: false
    };
  },
  created() {},
  mounted() {
    if (window.service.caution.open === 'on') this.warning();
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {},
  methods: {
    reload() {
      this.routeAlive = false;
      this.$nextTick(() => {
        this.routeAlive = true;
      });
    },
    reloadCaution() {
      try {
        // 舆情信息预警同步修改结果
        this.$refs.page.loadCautionList();
      } catch (e) {
        // window.console.log(e)
      }
    },
    showCaution() {
      this.$refs.caution.show();
    },
    warningClassName(type) {
      switch (type) {
        case '定时预警':
          return 'ds';
        case '循环预警':
          return 'xh';
        case '实时预警':
          return 'ss';
      }
    },
    warning() {
      const _this = this;
      setInterval(function() {
        let uid = _this.user.id;
        if (isNaN(uid)) return false;
        warningMsg({ id: uid })
          .then(res => {
            if (res.data.state) {
              let result = JSON.parse(res.data.data);
              if (Array.prototype.isPrototypeOf(result) && result.length >= 1) {
                _this.warningDialogLists = _this.warningDialogLists.concat(
                  result
                );
                if (_.size(_this.warningDialogLists) >= 200)
                  _this.warningDialogLists = _.takeRight(
                    _this.warningDialogLists,
                    100
                  );
                _this.hasCautionMsg = true;
                window.jQuery('.yjtx_dialog').fadeIn(500);
              }
            }
          })
          .catch(err => {
            window.console.error(err);
          });
      }, window.service.caution.interval);
    },
    hideWarning() {
      this.warningDialogLists = [];
      window.jQuery('.yjtx_dialog').fadeOut(500);
    },
    toWarningDetail(messageId) {
      window.open(`/caution/list?messageId=${messageId}`, '_blank');
    },
    showSystemNotify() {
      this.$refs.notify.show();
    }
  }
};
</script>
<style lang="scss" scoped>
.aside-box {
  position: fixed;
  width: 160px;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
}
.main-box {
  min-width: 1120px;
  margin-left: 160px;
}
</style>
<style scoped>
.el-header {
  line-height: 60px;
  background: #fff;
  padding: 0;
  box-shadow: 0px 2px 20px 0px rgba(65, 70, 76, 0.07);
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 1999;
}
.el-main {
  width: 100%;
}
</style>
<style>
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
/*预警提醒弹出框*/
.yjtx_dialog {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: #ffffff;
  display: none;
  box-shadow: 0px 4px 20px 0px rgba(194, 194, 194, 0.2);
  border-radius: 6px;
  width: 360px;
  height: 330px;
}
.yjtx_dialog h3 {
  background-color: #dc3636;
  line-height: 36px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0 15px;
}
.yjtx_dialog h3 .el-icon-error {
  float: right;
  font-size: 20px;
  margin-top: 8px;
  cursor: pointer;
  color: #fff;
}
.yjtx_dialog .cont {
  height: 290px;
}
.yjtx_dialog li {
  margin: 15px 15px 0;
  background-color: #ededed;
  border-radius: 6px;
  overflow: hidden;
  padding: 8px 15px;
  font-size: 12px;
  width: 300px;
  color: rgba(0, 0, 0, 0.8);
}
.yjtx_dialog h4 {
  line-height: 30px;
  height: 30px;
}
.yjtx_dialog h4 a {
  float: left;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
}
.yjtx_dialog h4 var {
  font-style: normal;
  float: right;
  font-size: 12px;
}
.yjtx_dialog h4 span {
  margin-left: 5px;
  float: right;
}
.yjtx_dialog p {
  line-height: 30px;
  font-size: 12px;
  width: 100%;
  overflow: hidden;
}
.yjtx_dialog p .f-l {
  float: left;
  padding-right: 10px;
}
.yjtx_dialog p .f-r {
  float: right;
}
.yjtx_dialog dd {
  float: left;
  padding-right: 10px;
  line-height: 22px;
}
.yjtx_dialog .ss .bt {
  line-height: 22px;
  max-height: 44px;
  overflow: hidden;
}
.yjtx_dialog .ss .bt span {
  display: inline-block;
  width: 42px;
  height: 18px;
  line-height: 18px;
  background: #e00;
  color: #fff;
  text-align: center;
  margin-right: 10px;
  border-radius: 5px;
}
.yjtx_dialog .w-104 {
  max-width: 104px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.8);
}
.yjtx_dialog .t-r {
  float: right;
  padding-left: 10px;
}
.yjtx_dialog li span.ico {
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-top: 8px;
}
.yjtx_dialog .ds span.ico {
  background-image: url(../assets/images/common/icon/dingshi.svg);
}
.yjtx_dialog .ss span.ico {
  background-image: url(../assets/images/common/icon/shishi.svg);
}
.yjtx_dialog .xh span.ico {
  background-image: url(../assets/images/common/icon/xunhuan.svg);
}
</style>
