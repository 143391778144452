<template>
  <el-container>
    <el-dialog
      :close-on-click-modal="false"
      @closed="onClose"
      :visible.sync="warningDialog.visible"
      class="Dialog_style1 close_warningDialog"
    >
      <div class="mod-title" slot="title">关闭预警</div>
      <div class="cont" v-loading="loading">
        <div class="mod-list">
          <ul>
            <li>
              <h3>
                <span class="num-tit">序号</span>
                <span class="mod-list-title1">方案名称</span>
                <span class="tag">设定状态</span>
                <span class="mod-list-title2">预警条件</span>
              </h3>
            </li>
          </ul>
          <div class="warningDialog">
            <vue-scroll>
              <ul>
                <li v-for="(row, index) in warningDialog.list" :key="index">
                  <span class="num bg-555" v-if="index == 0">1</span>
                  <span class="num bg-689" v-else-if="index == 1">2</span>
                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                  <span class="num" v-else-if="index >= 3">{{
                    index + 1
                  }}</span>
                  <span class="mod-list-title1">{{ row.name }}</span>
                  <span class="tag">
                    <var>{{ row.notify == 1 ? '启用 ' : '停用 ' }}</var>
                    <el-switch
                      @change="warningEnableChange(row)"
                      :inactive-value="0"
                      :active-value="1"
                      v-model="row.notify"
                    >
                    </el-switch>
                  </span>
                  <span class="mod-list-title2 nowrap">{{ row.where }}</span>
                </li>
              </ul>
            </vue-scroll>
          </div>
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>
<script type="application/javascript">
import { warningNotify, warningList } from '@/api/caution';
export default {
  name: 'caution-dialog',
  data() {
    return {
      warningDialog: { visible: false, list: [] },
      loading: false,
      hasEdited: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    show() {
      this.warningDialog.visible = true;
      this.loadCautionList();
    },
    onClose() {
      if (this.hasEdited) {
        this.$emit('reloadCaution');
      }
      this.hasEdited = false;
    },
    loadCautionList() {
      this.loading = true;
      const _this = this;
      warningList({ enable: 'all', type: 'expr' })
        .then(res => {
          if (res.data.state) {
            this.warningDialog.list = res.data.data.map(plan => {
              plan.where = _this.calculationWhere(plan);

              return plan;
            });
            this.loading = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    calculationWhere(plan) {
      var where = '';
      switch (plan.condition.period.value) {
        case 'timer':
          where += '定时预警，';
          break;
        case 'frequency':
          where += '循环预警，';
          break;
        case 'presently':
          where += '实时预警，';
          break;
      }
      where +=
        plan.condition.mute.value.indexOf('push_week') > -1
          ? '周末不预警，'
          : '周末预警，';
      where += '推送方式: ' + plan.condition.terminal.value.join('，');
      return where;
    },
    warningEnableChange(plan) {
      warningNotify({
        id: plan.plan_id,
        notify: plan.notify
      })
        .then(res => {
          if (res.data.state) {
            this.$message.success('更新状态成功');
            this.hasEdited = true;
          } else {
            plan.notify = plan.notify == 0 ? 1 : 0;
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('更新状态失败，服务错误');
        });
    }
  }
};
</script>
<style scoped>
.close_warningDialog >>> .el-dialog {
  width: 600px;
}
.close_warningDialog .cont {
  height: 260px;
}
.close_warningDialog .mod-list-title1 {
  width: 21%;
}
.close_warningDialog .tag {
  width: 21%;
}
.close_warningDialog .mod-list-title2 {
  width: 43%;
}
.warningDialog {
  height: 185px;
  font-weight: 400;
}
</style>
